import React, { useState } from "react";
import './orderActions.css';

import Button from "../../components/Button/Button";
import Header from '../../components/Header/Header'
import Modal from "../../components/Modal/Modal";
import Error from "../error/error";

import router from "../../utils/router";
import { useStoreon } from "storeon/react";
import { api } from "../../api/api";

const OrderActions = () => {
    const order = router.getModalParams();
    const [isOrderRemoving, setOrderRemoving] = useState(false);
    const { dispatch } = useStoreon();

    const close = () => {
        router.closeModal();
    }

    const remove = () => {
        setOrderRemoving(true);

        api.cancelOrder(order.id).then(result => {
            if (!result.status) {
                return router.openModal(<Error title="Ошибка">{result.error.description}</Error>);
            }

            setOrderRemoving(false);
            dispatch('order/remove', order.id);
            close();
        })
    }

    return (
        <Modal>
            <Header>Заказ {order.num}</Header>

            <Button size="l" mode="destructive" wide={true} onClick={remove} loading={isOrderRemoving}>Удалить заказ из списка</Button>
            <Button mode="secondary" size="l" wide={true} onClick={close}>Закрыть окно</Button>
        </Modal>
    )
}

export default React.memo(OrderActions);