import React from "react";
import './Router.css';

class Router extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentId: '',
            activePanels: [],
            modal: null
        }

        this.timeout = null;
    }

    componentDidMount() {
        window.addEventListener('navigate', event => {
            let {pageId, params} = event.detail;
            this.switchPage(pageId, params)
        })

        window.addEventListener('openModal', event => {
            const { modal } = event.detail;
            this.openModal(modal);
        })

        window.addEventListener('closeModal', () => {
            this.closeModal();
        })
    }

    switchPage(pageId, params) {
        React.Children.forEach(this.props.children, page => {
            if (page.props.id === pageId) {
                this.setState({
                    currentId: pageId,
                    activePanels: [...this.state.activePanels, page],
                    params
                });
            }
        } );

        window.scrollTo(0, 0);
    } 

    componentDidUpdate(_, prevState) {
        let prev = document.getElementById(this.state.activePanels[0]?.props.id);

        if (prevState.currentId === '' ) {
            prev.classList.add('Page--active');
        }

        if (this.state.activePanels.length <= 1 ) return;

        prev?.classList.add('Page--out');
        prev?.classList.remove('Page--active');

        let current = document.getElementById(this.state.activePanels[1]?.props.id);
        current?.classList.add('Page--active');

        this.timeout = setTimeout(() => this.deletePrev(), 500);
    }

    deletePrev() {
        this.setState({activePanels: [this.state.activePanels[1]]})
    }

    openModal(modal) {
        this.setState({ modal });
    }

    closeModal() {
        this.setState({ modal: null });
    }
    
    render() {
        return <div className="Router">
            {this.state.activePanels}
            {this.state.modal &&
                <div className="Router__popout">
                    {this.state.modal}
                </div>
            }
        </div>
    }
}

export default React.memo(Router);