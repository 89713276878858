import { useState, useRef, useEffect, Component } from 'react'
import { formatTime } from '../../utils/timer'

class Timer extends Component {
    constructor(props) {
        super(props);

        this.requestId = 0;
        this.last = props.initialTime

        this.state = {
            time: props.initialTime,
            timer: 0
        }

        this.action = this.action.bind(this);
    }

    componentDidMount() {
        this.action();
    }

    componentDidUpdate() {
        this.action = this.action.bind(this);
    }

    action() {
        const now = Date.now();
        let counter = Math.round(Math.abs(now - this.props.initialTime) / 1000)

        if (this.requestId === null) return;
        
        if (Math.abs(now - this.last) <= 1000) {
            this.requestId = requestAnimationFrame(this.action);
            return;
        }

        this.setState({timer: counter});
        this.props.onChange(counter);

        this.last = now;
        this.requestId = requestAnimationFrame(this.action)
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.requestId);
    }

    render() {
        return formatTime(this.state.timer);
    }
}

export default Timer;