const formatTime = seconds => {
    let minutes = parseInt(seconds / 60);
    let sec = seconds - minutes * 60;

    let s = sec < 10 ? '0' + sec : sec;
    return minutes + ':' + s;    
}

export {
    formatTime
}