class Router {
    constructor() {
        this.data = {}
        this.modal = null
        this.modalParams = {}

        window.addEventListener('keydown', event => {
            if (event.key === 'Escape') {
                this.closeModal();
            }
        })
    }

    navigate(pageId, params = {}) {
        if (this.data.pageId === pageId) return;
        this.data = {pageId, params}

        let event = new CustomEvent('navigate', {
            detail: this.data
        });

        window.dispatchEvent(event);
        this.closeModal();
    }

    openModal(modal, params = {}) {
        this.modal = modal;
        this.modalParams = params

        let event = new CustomEvent('openModal', {
            detail: {
                modal,
                params
            }
        })

        window.dispatchEvent(event);
    }

    closeModal() {
        this.modal = null;
        let event = new CustomEvent('closeModal')
        window.dispatchEvent(event)
    }

    getModal() {
        return this.modal;
    }

    getModalParams() {
        return this.modalParams
    }

    getData() {
        return this.data;
    }

    getPageId() {
        return this.data.pageId;
    }

    getParams() {
        return this.data.params;
    }
}


const router = new Router();
export default router;