import React from 'react';

import Modal from '../../components/Modal/Modal';
import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import router from '../../utils/router';

const Error = ({ title, children }) => {
    const close = () => {
        router.closeModal();
    }

    return (
        <Modal>
            <Header>{title}</Header>
            {children}

            <Button wide={true} size="l" onClick={close} mode="secondary">Закрыть</Button>
        </Modal>
    )
}

export default React.memo(Error);