const onInit = () => {
    return {
        orders: []
    }
}

const onAdd = ({ orders }, newOrders) => {
    return {
        orders: [...orders, ...newOrders]
    }
}

const onRemove = ({ orders }, orderId) => {
    return {
        orders: orders.filter(order => order.id !== orderId)
    }
}

const onSet = (_, data) => {
    return {
        orders: [...data]
    }
}

const onOrderItemStatusChange = ({ orders }, { orderId, itemId, currentStatus }) => {
    orders = orders.map(order => {
        if (order.id !== orderId) return order;
        order.items = order.items.map(item => {
            if (item.id === itemId) item.status = currentStatus;
            return item;
        })

        return order;
    })

    return {
        orders: [...filterCompletedOrders(orders)]
    }
}

const filterCompletedOrders = orders => {
    return orders.filter(order => {
        let { items } = order;
        let isCompleted = true;

        items.forEach(item => {
            isCompleted = isCompleted && item.status === 'DISH_READY';
        })

        return !isCompleted;
    })
}



export default function orders(store) {
    store.on('@init', onInit);
    store.on('orders/set', onSet);
    store.on('order/add', onAdd);
    store.on('order/remove', onRemove)
    store.on('order/itemStatusChange', onOrderItemStatusChange);
}