import React, { useEffect, useState } from 'react'
import './auth.css';

import Page from '../../components/Page/Page';
import Header from '../../components/Header/Header';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import router from '../../utils/router';
import { check, directAuth } from '../../utils/auth';

const Auth = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        check();
    }, [])

    const onLoginChange = event => {
        const { value } = event.currentTarget;
        setLogin(value)
    }

    const onPasswordChange = event => {
        const { value } = event.currentTarget;
        setPassword(value);
    }

    const enter = () => {
        setLoading(true);
        
        directAuth(login, password, result => {
            setLoading(false);
            router.navigate('board');
        }, () => {
            setLoading(false);
        })
    }

    return (
        <Page className="Auth" id="auth">
            <div className="Auth__content">
                <Header>Вход</Header>
                <Input placeholder="Логин" value={login} onChange={onLoginChange} />
                <Input placeholder="Пароль" value={password} onChange={onPasswordChange} type="password" />
                
                <Button size="l" wide={true} loading={isLoading} onClick={enter}>Войти</Button>
            </div>
        </Page>
    )
}

export default React.memo(Auth);