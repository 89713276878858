import React from "react";
import './Modal.css';

import router from "../../utils/router";

const Modal = ({ children }) => {
    const onMaskClick = event => {
        if (!event.target.classList.contains('SettingsMask')) {
            return;
        }

        router.closeModal();
    }

    return (
        <div className="ModalMask" onClick={onMaskClick}>
            <div className="Modal">
                {children}
            </div>
        </div>
    )
}

export default React.memo(Modal);