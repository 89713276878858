import React, { useRef } from "react";
import './Button.css';

import Ripple from "../Ripple/Ripple";


const Button = ({size, children, href, uppercase, wide, onClick, loading, className, mode, ...restProps}) => {
    let timeout = null;
    let ref = useRef();
    size = size || 's';
    mode = mode || 'primary';

    let classes = ["Button", `Button--${size}`, `Button--${mode}`, className]
    if (uppercase) classes.push("Button--uppercase")
    if (wide) classes.push("Button--wide");
    if (loading) classes.push("Button--loading");

    classes = classes.join(' ')

    let click = event => {
        if (typeof onClick === 'function') onClick(event);

        clearTimeout(timeout)
        ref.current.classList.add('Button--active')

        timeout = setTimeout(() => {
            if ( !ref.current ) return;
            ref.current.classList.remove('Button--active')
        }, 100);

    }

    const Link = <a href={href} className={classes} onClick={click} ref={ref} {...restProps}>
        <Ripple />
        {children}
    </a>

    const Btn = <button className={classes} onClick={click} ref={ref} {...restProps}>
        <Ripple />
        {children}
    </button>

    return href ? Link : Btn;
}

export default React.memo(Button)