import React, { useState } from "react";
import './settings.css';

import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";

import router from "../../utils/router";
import nosleep from "../../utils/noSleep";
import { logout } from '../../utils/auth';

const Settings = () => {
    const [isLoggingOut, setLoggingOut] = useState(false);

    const toogleFullscreenMode = () => {
        if (!document.fullscreenElement) {
            nosleep.enable();
            return document.body.requestFullscreen({
                navigationUI : "hide"
            });
        }

        nosleep.disable();
        return document.exitFullscreen();
    }

    const close = () => {
        router.closeModal();
    }

    const reload = () => {
        window.location.reload();
    }

    const exitAccount = () => {
        setLoggingOut(true);

        logout(() => {
            router.navigate('auth');
        })
    }

    return (
        <Modal>
            <div className="Settings">
                <Button mode="secondary" size="l" wide={true} onClick={toogleFullscreenMode}>Полноэкранный режим</Button>
                <Button mode="secondary" size="l" wide={true} onClick={reload}>Перезагрузить приложение</Button>
                <Button mode="secondary" size="l" wide={true} onClick={exitAccount} loading={isLoggingOut}>Выход из аккаунта</Button>
                <Button mode="secondary" size="l" wide={true} onClick={close}>Закрыть</Button>
            </div>
        </Modal>
    )
}

export default React.memo(Settings);