import { api } from "../api/api";
import router from "./router";
import Error from "../modals/error/error";

const errorHandler = (result, onError) => {
    if (typeof onError === 'function') {
        onError(result);
    }

    router.openModal(<Error title="Ошибка">{result.error.description}</Error>)
}

const directAuth = (login, password, onSuccess, onError) => {
    api.login(login, password).then(result => {
        if (!result.status) return errorHandler(result, onError);

        let accessToken = result.response.access_token;
        window.localStorage.setItem('accessToken', accessToken);

        if (typeof onSuccess === 'function') onSuccess(result.response);
    }).catch(onError);
}

const check = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (!accessToken) return router.navigate('auth');

    api.check().then(result => {
        const page = result.response.result ? 'board' : 'auth';
        if (router.getData().pageId !== page) {
            router.navigate(page);
        }
    })
}

const logout = (onSuccess) => {
    api.logout().then(result => {
        if (!result.status) return errorHandler(result);
        if (typeof onSuccess === 'function') onSuccess(result.response);
    })
}

export {
    directAuth,
    check,
    logout
}