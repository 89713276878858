import store from '../store/store';

class Connector {
    constructor(apiEndpoint, accessToken) {
        this.apiEndpoint = apiEndpoint;
        this.accessToken = accessToken;
    }


    async get(path, data = {}) {
        return this.request(path, 'get', data);
    }

    async post(path, data = {}) {
        return this.request(path, 'post', data);
    }


    async request(path, method, data) {
        data = data || {}
        data.access_token = window.localStorage.getItem('accessToken');

        let fullPath = `${this.apiEndpoint}${path}`;
        let params = _encodeQueryData(data);

        if (method === 'get') fullPath += `?${params}`;

        let settings = {
            method,
            headers: {
                ContentType: 'form/multipart'
            },
        };

        if (method === 'post') {
            settings.body = new FormData();
            
            Object.keys(data).forEach(key => {
                if (data) settings.body.append(key, data[key]);
            });
        }

        const response = await fetch(fullPath, settings);
        return await response.json();
    }
}

function _encodeQueryData(data) {
    return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join("&");
}


class API {
    constructor(connector) {
        this.connector = connector;
    }

    async login(login, password) {
        const path = 'auth.direct';
        return await connector.get(path, { login, password })
    }

    async check() {
        const path = 'auth.checkAccessToken';
        return await connector.get(path, {});
    } 

    async logout() {
        const path = 'auth.logout';
        return await connector.get(path, {});
    }

    async getOrders(update_id = 0) {
        const path = 'order.get';
        return await connector.get(path, { update_id })
    }

    async changeOrderItemStatus(order_id, item_id, status) {
        const path = 'order.changeItemStatus';
        return await connector.get(path, { order_id, item_id, status } );
    }

    async syncOrders(data) {
        const path = 'order.sync';
        return await connector.post(path, { data });
    }

    async cancelOrder(order_id) {
        const path = 'order.cancel';
        return await connector.post(path, { order_id });
    }
}

const access_token = window.localStorage.getItem('accessToken');

const API_ENDPOINT = 'https://api.izi.funny-dev.ru/api/'
const connector = new Connector(API_ENDPOINT, access_token);
const api = new API(connector);

export {
    Connector,
    connector,
    api
}