import React from "react";
import './FullPagePlaceholder.css';

const FullPagePlaceholder = ({ icon, title, text, ...restProps }) => {
    return <div className="FullPagePlaceholder" {...restProps}>
        <div className="FullPagePlaceholder__container">
            <div className="FullPagePlaceholder__icon" style={{background: `url(${icon})`}}></div>
            <div className="FullPagePlaceholder__title">{title}</div>
            <div className="FullPagePlaceholder__text">{text}</div>
        </div>
    </div>
}

export default React.memo(FullPagePlaceholder);