import store from '../store/store';
import { api } from '../api/api';

window.updateInterval = 0;
const UPDATE_INTERVAL_SEC = 5000;

const update = () => {
    const updateId = window.localStorage.getItem('updateId') || 0;

    api.getOrders(updateId).then(result => {
        if (!result.status) return;
        let { response } = result;

        window.localStorage.setItem('updateId', response.update_id);
        store.dispatch('order/add', response.orders)
    })

}

const sync = () => {
    const { orders } = store.get();
    let data = JSON.stringify(prepareOrdersStore(orders));

    api.syncOrders(data).then(result => {
        if (!result.status) return;

        window.localStorage.setItem('updateId', result.response.update_id);
        store.dispatch('orders/set', result.response.orders);
    })
}

const subscribe = () => {
    unsubscribe();

    sync();
    window.updateInterval = setInterval(sync, UPDATE_INTERVAL_SEC);
}

const unsubscribe = () => {
    clearInterval(window.updateInterval);
}

// Приводит хранилище к такому виду:
// [ [orderId, [[itemId, status]]] ]
const prepareOrdersStore = data => {
    let result = [];

    data.forEach(item => {
        let items = item.items.map(position => {
            return [position.id, position.status];
        })

        result.push([item.id, items])
    })

    return result;
}

export {
    subscribe,
    update,
    unsubscribe
}