import React from "react";
import './Header.css';

const Header = ({children, level, uppercase, className, right, ...restProps}) => {
    level = level || 1
    let classes = ["Header", `Header--level-${level}`, className];
    if (uppercase) classes.push("Header--uppercase")

    return <div className={classes.join(' ')} {...restProps}>
        {children}
        {right && <div className="Header__right">{right}</div>}
    </div>
}

export default React.memo(Header)