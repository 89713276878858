import router from './utils/router'

import Router from './components/Router/Router'
import Board from './pages/board/board';
import OfflineIndicator from './components/OfflineIndicator/OfflineIndicator';

import { useEffect } from 'react';
import Auth from './pages/auth/auth';
import { check } from './utils/auth';

const App = () => {
    useEffect(() => {
        check();
    }, []);


    return (
        <>
            <Router>
                <Board id="board" />
                <Auth id="auth" />
            </Router>

            <OfflineIndicator />
        </>
    )
}

export default App;
