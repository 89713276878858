import React, { useEffect, useState } from "react";
import './OrderBox.css';

import Button from "../Button/Button";
import OrderItem from "../OrderItem/OrderItem";
import OrderActions from "../../modals/orderActions/orderActions";

import { Icon28MoreHorizontal } from '@vkontakte/icons';
import router from "../../utils/router";

const BLOCK_SIZE = 210;

const OrderBox = ({ order, children }) => {
    let count = order.items.length;
    let [width, setWidth] = useState('auto');
    
    useEffect(() => {
        const root = document.getElementById('root');

        const calcWidth = () => {
            let height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 100;
            let w = 'auto';
    
            if (Math.round(height / (count * BLOCK_SIZE)) <= 1) {
                // Не влезает
                let colSize = parseInt(height / BLOCK_SIZE);
                let rowSize = Math.ceil(count / colSize);
        
                w = BLOCK_SIZE * rowSize + 'px';
            }
    
            setWidth(w);
        }

        calcWidth();
        window.addEventListener('resize', calcWidth);
        root.addEventListener('fullscreenChange', calcWidth);

        return () => {
            window.removeEventListener('resize', calcWidth)
            root.addEventListener('fullscreenChange', calcWidth);
        }
    }, [count])

    const openActions = () => {
        router.openModal(<OrderActions />, order);
    }


    return (
        <div className="OrderBox">
            <div className="OrderBox__actions">
                <div className="OrderBox__num">{order.num}</div>
                
                <Button size="s" mode="secondary" onClick={openActions}>
                    <Icon28MoreHorizontal />
                </Button>
            </div>

            <div className="OrderBox__content" style={{width: width}}>
                {order.items.map((position, i) => (
                    <OrderItem key={i} orderId={order.id} itemId={position.id} data={position} />
                ))}
                {children}
            </div>
        </div>
    )
}

export default React.memo(OrderBox);