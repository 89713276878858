import { memo, useEffect, useState } from 'react';
import './OfflineIndicator.css';

const OfflineIndicator = () => {
    const [isOffline, setOffline] = useState(!navigator.onLine);

    const updateStatus = () => {
        setOffline(!navigator.onLine);
    }

    useEffect(() => {
        window.addEventListener('online', updateStatus);
        window.addEventListener('offline', updateStatus);

        return () => {
            window.removeEventListener('offline', updateStatus);
            window.removeEventListener('offline', updateStatus);
        }
    }, []);

    return (
        <>
            {isOffline && <div className="OfflineIndicator">Приложение оффлайн. Проверьте подключение к интернету</div>}
        </>
    );
}

export default memo(OfflineIndicator);