import React, { useEffect } from "react";
import './board.css';

import Page from '../../components/Page/Page'
import Container from '../../components/Container/Container'
import OrderBox from "../../components/OrderBox/OrderBox";
import Button from '../../components/Button/Button';
import FullPagePlaceholder from "../../components/FullPagePlaceholder/FullPagePlaceholder";

import Settings from "../../modals/settings/settings";

import { useStoreon } from 'storeon/react';
import { subscribe } from "../../utils/orders";

import chillIcon from '../../images/icons/emoji/beach-with-umbrella.png';
import router from "../../utils/router";

const Board = () => {
    const { orders } = useStoreon('orders');

    const openSettings = () => {
        router.openModal(<Settings />)
    }

    // Подписка на заказы
    useEffect(() => {
        subscribe();
    }, []);

    return (
        <Page id="board">
            <div className="Board__actions">
                <Button size="l" mode="secondary" onClick={openSettings}>Настройки</Button>
            </div>

            <Container>
                {orders.length === 0 && 
                    <FullPagePlaceholder
                        icon={chillIcon}
                        title="Заказов нет"
                        text="Список заказов пуст, можно расслабиться." />
                }
            </Container>

            <div className="Board">
                {orders.length > 0 && orders.map(order => (
                    <OrderBox key={order.id} order={order} />
                ))}                  
            </div>
        </Page>
    )
}

export default React.memo(Board);