import { memo, useEffect, useState } from 'react';
import './Input.css';


const Input = ({invalidNotice, maxLength, onChange, ...restProps}) => {
    const [value, setValue] = useState((restProps.value || ''));

    const update = e => {
        e.preventDefault();

        if (typeof maxLength === 'number' && e.currentTarget.value.length > maxLength) {
            return;
        }
        
        if (typeof onChange === 'function') onChange(e);
    }

    useEffect(() => {
        setValue(restProps.value || '');
    }, [restProps.value])

    return (
        <div className={"Input" + (maxLength ? ' Input--with-counter' : '')}>
            <input className="Input__element" value={value} onChange={update} {...restProps} />
            {typeof maxLength !== 'undefined' && <div className="Input__counter">{maxLength - value.length}</div>}
            {invalidNotice && <div className="Input__notice">{invalidNotice}</div>}
        </div>
    );
}

export default memo(Input)