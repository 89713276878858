import React, { useRef } from "react";
import './Ripple.css';

const Ripple = ({children, className, ...restProps}) => {
    let ref = useRef()
    let container = useRef()

    let getParameters = (event) => {
        let rect = ref.current.getBoundingClientRect()
        let src = event

        if (event.type === 'touchstart') {
            src = event.targetTouches[0].clientX;
        }

        let {clientX, clientY} = src;

        let x = (clientX - rect.left);
        let y = (clientY - rect.top);

        let w = ref.current.offsetWidth;
        let h = ref.current.offsetHeight;

        let size = Math.max(1.5 * w, 1.5 * h);

        return [x, y, size]
    }

    let start = (event) => {
        let rippleElement = document.createElement('div')
        rippleElement.classList.add('Ripple__item')

        let [x, y, size] = getParameters(event)

        rippleElement.style.left = `${x}px`;
        rippleElement.style.top = `${y}px`;

        rippleElement.style.width = `${size}px`
        rippleElement.style.height = `${size}px`

        rippleElement.style.margin = `-${size / 2}px`

        container.current.appendChild(rippleElement)

        setTimeout(() => {
            if (!rippleElement) return;
            rippleElement.remove()
        }, 600)
    }

    return (
        <div className="Ripple" onClick={start} ref={ref} {...restProps}>
            <div className="Ripple__container" ref={container}></div>
        </div>
    )
}

export default React.memo(Ripple)