import React, { useEffect, useRef, useState } from "react";
import './OrderItem.css';

import Timer from "../Timer/Timer";
import Ripple from "../Ripple/Ripple";
import TechMap from "../../modals/techMap/techMap";

import { useStoreon } from "storeon/react";
import { api } from "../../api/api";
import router from "../../utils/router";

const TIME_LIMIT_VISIBILITY = 25;
const TIME_LIMIT_SHAKE = 10;
const STATUS_FLOW = {
    DISH_NEW: 'DISH_TIMEOUT',
    DISH_ACCEPTED: 'DISH_ACCEPTED_TIMEOUT',

    DISH_TIMEOUT: 'DISH_TIMEOUT',
    DISH_ACCEPTED_TIMEOUT: 'DISH_ACCEPTED_TIMEOUT',
    DISH_READY: 'DISH_READY'
};

const LONG_PRESS_DURATION = 500;


const OrderItem = ({ orderId, itemId, data }) => {
    const { dispatch } = useStoreon();
    const timerView = useRef(null);
    const [currentStatus, setCurrentStatus] = useState(data.status); 
    const [time, setTime] = useState(data.next_status_at * 1000);
    const [timer, setTimer] = useState(0);

    const longPressTimer = useRef(0);
    const longPressStartTime = useRef(0);

    const onClick = () => {
        if (currentStatus === 'DISH_READY') {
            return;
        }

        if (currentStatus === 'DISH_ACCEPTED' || currentStatus === 'DISH_ACCEPTED_TIMEOUT') {
            return setCurrentStatus('DISH_READY')
        }

        setTime(Date.now() + data.dish.cooking_time * 1000);
        setCurrentStatus('DISH_ACCEPTED')
    }

    useEffect(() => {
        setCurrentStatus(data.status);
        setTime(data.next_status_at * 1000)
    }, [data.status, data.next_status_at]);

    useEffect(() => {
        let delta = (Date.now() - time) / 1000;

        if (delta <= 0) {
            if (Math.abs(delta) <= TIME_LIMIT_VISIBILITY) {
                timerView.current.classList.add('OrderItem__timer--visible');
            }

            if (Math.abs(delta) <= TIME_LIMIT_SHAKE) {
                timerView.current.classList.add('OrderItem__timer--shake');
            }
        }

        if (delta >= 0) {
            setCurrentStatus(STATUS_FLOW[currentStatus])
            timerView.current.classList.remove('OrderItem__timer--visible');
            timerView.current.classList.remove('OrderItem__timer--shake');
        }
    }, [timer, time, currentStatus]);


    // Sync status with store
    useEffect(() => {
        if ( currentStatus !== 'DISH_ACCEPTED' && currentStatus !== 'DISH_ACCEPTED_TIMEOUT' ) {
            dispatch('order/itemStatusChange', { orderId, itemId, currentStatus });
        }
        
        api.changeOrderItemStatus(orderId, itemId, currentStatus);
    }, [currentStatus, dispatch, orderId, itemId])



    const onLongPressStart = () => {
        longPressStartTime.current = Date.now();
        longPressTimer.current = setTimeout(() => {
            if (currentStatus === 'DISH_ACCEPTED' || currentStatus === 'DISH_ACCEPTED_TIMEOUT') {
                router.openModal(<TechMap />, data);
            }
        }, LONG_PRESS_DURATION);
    }


    const onLongPressEnd = event => {
        const delta = Date.now() - longPressStartTime.current;
        if (delta < LONG_PRESS_DURATION) {
            return clearTimeout(longPressTimer.current);
        }

        event.preventDefault();
    }

    let classes = ['OrderItem', `OrderItem--${currentStatus}`]
    
    return (
        <div className={classes.join(' ')}
             onClick={onClick}
             onTouchStart={onLongPressStart}
             onTouchEnd={onLongPressEnd}
             onMouseDown={onLongPressStart}
             onMouseUp={onLongPressEnd}>
            <div className="OrderItem__title">{data.dish.name}</div>

            <div className="OrderItem__timer" ref={timerView}>
                {currentStatus !== 'DISH_READY' && <Timer initialTime={time} onChange={setTimer} />}
            </div>

            <Ripple />
        </div>
    );
}

export default React.memo(OrderItem);