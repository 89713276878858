import React from "react";
import Modal from "../../components/Modal/Modal";
import Header from '../../components/Header/Header'
import Button from "../../components/Button/Button";

import nl2br from "react-nl2br";
import router from "../../utils/router";

const TechMap = () => {
    const { dish } = router.getModalParams();

    const close = () => {
        router.closeModal();
    }

    return (
        <Modal>
            <Header>{dish.name}</Header>
            <p>{nl2br(dish.tech_map)}</p>
            <Button mode="secondary" wide={true} size="l" onClick={close}>Закрыть</Button>
        </Modal>
    );
}

export default React.memo(TechMap);